var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "flex",
      "height": "100%"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.loginUser.apply(null, arguments);
      }
    }
  }, [_c('v-row', {
    staticClass: "main-row",
    staticStyle: {
      "background-color": "white"
    }
  }, [_c('v-col', {
    staticClass: "login-col"
  }, [_c('v-col', {
    staticClass: "form-col"
  }, [_c('div', {
    staticClass: "header-row"
  }, [_c('v-row', {
    staticClass: "title-row"
  }, [_c('h1', {
    staticClass: "login-title"
  }, [_vm._v(_vm._s(_vm.$t("Login")))])]), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('FirstName'),
      "icon-left": "email.svg",
      "id": "email-input"
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "margin-top": "8px"
    },
    attrs: {
      "placeholder": _vm.$t('Password'),
      "type": "password",
      "icon-left": "lock.svg",
      "id": "password-input"
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('v-row', {
    staticClass: "login-row"
  }, [_c('button', {
    staticClass: "login-button content-semibold",
    attrs: {
      "name": "login-button"
    },
    on: {
      "click": _vm.loginUser
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Login")) + " ")])]), _vm.responseMessage ? _c('v-row', {
    staticClass: "error-row"
  }, [_c('p', [_vm._v(_vm._s(_vm.responseMessage))])]) : _vm._e()], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }